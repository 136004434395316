import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, retry, throwError, timer } from 'rxjs';

import { AppShellErrorSeverity, AppShellErrorStatus, ErrorMessage } from '@priva/appshell';

import { HttpError } from 'app/core/error/error.enum';

export const maxRetries = 0;
export const delayMs = 2000;

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            retry({ count: maxRetries, delay: (err) => this.createNotifier$(err) }),
            catchError((error: HttpErrorResponse) => {
                const errorMessage = this.errorMessage(error);
                return throwError(() => errorMessage);
            }),
        );
    }

    private createNotifier$(err: HttpErrorResponse): Observable<unknown> {
        const isServerError = err.status === 500;
        return isServerError ? timer(delayMs) : throwError(() => err);
    }

    private errorMessage(error: HttpErrorResponse): ErrorMessage {
        if (error instanceof HttpErrorResponse) {
            switch (error.status) {
                case HttpError.NotFound:
                    return {
                        messageLabel: 'NOT_FOUND',
                        severity: AppShellErrorSeverity.Error,
                        status: AppShellErrorStatus.Active,
                        details: null,
                        source: '',
                        uuid: '',
                    };
                case HttpError.Forbidden:
                    return {
                        messageLabel: 'NOT_SHARED',
                        severity: AppShellErrorSeverity.Error,
                        status: AppShellErrorStatus.Active,
                        details: null,
                        source: '',
                        uuid: '',
                    };
                case HttpError.TooManyRequests:
                    return {
                        messageLabel: 'TOO_MANY_REQUESTS',
                        severity: AppShellErrorSeverity.Error,
                        status: AppShellErrorStatus.Active,
                        details: null,
                        source: '',
                        uuid: '',
                    };
                case HttpError.InternalServerError:
                    return {
                        messageLabel: 'INTERNAL_SERVER_ERROR',
                        severity: AppShellErrorSeverity.Error,
                        status: AppShellErrorStatus.Active,
                        details: null,
                        source: '',
                        uuid: '',
                    };
                default:
                    return {
                        messageLabel: 'OTHER',
                        severity: AppShellErrorSeverity.Error,
                        status: AppShellErrorStatus.Active,
                        details: null,
                        source: '',
                        uuid: '',
                    };
            }
        } else {
            return {
                messageLabel: 'OTHER',
                severity: AppShellErrorSeverity.Error,
                status: AppShellErrorStatus.Active,
                details: null,
                source: '',
                uuid: '',
            };
        }
    }
}
